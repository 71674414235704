<template>
  <div>
    <!-- Date -->
    <div class="mt-2">
      <b-row class="m-0" v-if="!onlyDate">
        <b-col class="shape-first-cell whitout-bg"> <img :src="iconCalendar" alt="calendar" />
          <h4 v-if="!this.$route.name.includes('yield')" class="subtitle-form">{{ $t("harvestStorage.list.date") }}</h4>
          <h4 v-else class="subtitle-form">{{ $t("yield.list.harvest_date") }}</h4>
        </b-col>
        <b-col class="shape-first-cell whitout-bg"> <img :src="iconClock" alt="clock" />
          <h4 class="subtitle-form">{{ $t("harvestStorage.edit.hour") }}</h4>
        </b-col>
      </b-row>
      
      <b-row class="m-0 bg-white">
        <b-col class="text-infos">
          {{  formatDate(value, "date") }}
        </b-col>
        <b-col v-if="!onlyDate" class="text-infos">
          {{ formatDate(value, "time") }}
        </b-col>
      </b-row>
    </div>
  </div>
  
    
</template>

<script>
import DetailUpdateMixin from "@/mixins/DetailUpdateMixin";
import clock from "@/assets/images/clock.svg";
import calendar from "@/assets/images/calendar.svg";
import {formatDate} from "@/utils/commons";

export default {
  mixins: [DetailUpdateMixin],
  props: {
    value: {
      type: String,
      default: "",
    },
    empty: {
      type: String,
      default: "",
    },
    onlyDate: {
      // si True, alors le champ de date ne contient pas d'information sur l'heure
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      iconClock: clock,
      iconCalendar: calendar,
    };
  },
  methods: {
    formatDate,
    // /**
    //  * @description Retourne une date ou une heure.
    //  * @param {Date}        date Une date.
    //  * @param {string}      opt Argument optionnel qui peut être 'time' pour l'heure ou 'date' pour la date.
    //  * @return {string}     Retourne une date (jj/mm/aaa) ou une heure (HH:mm) au format string.
    //  */
    // formatDate(date, opt = "") {
    //   if (date != null) {
    //     if (opt == "time") return new Date(date).toLocaleTimeString().slice(0, 5);
    //     else if (opt == "date") return new Date(date).toLocaleDateString();
    //   }

    //   return this.$t("is_empty_feminine");
    // },
  },
}
</script>

<style lang="scss" scoped>
img {
  width: 1.5em;
  float: left;
  margin: auto 5px 5px auto;
}

/**
 * Color text for empty list.
 */
.empty-attr {
  text-align: left;
  color: $danger-color;
  font-size: 13.5px;
  padding: 5px 8px 5px 10px;
  border-radius: 4px;
}
</style>